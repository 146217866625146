import styled from 'styled-components';

export const Subheading = styled.h2`
  font-size: 24px;
  margin-bottom: 5px;
  margin-top: 0;
  padding-top: 4.5em;
  margin-top: -4.5em;

  /* Safari-only */
  @supports (-webkit-hyphens:none) {
    padding-top: 80px;
    margin-top: -80px;
  }

  &.marginBottom {
    margin-bottom: 25px;
  }
`;

export const Notice = styled.span`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: var(--fontWeight-medium);
  color: #5b6575;

  @media (max-width: 600px) {
    font-size: 12px;
    display: inline-block;
    line-height: 1.4;
  }

  svg {
    margin: 0 3px;
    color: #00852c;
    font-size: 14px;

    @media (max-width: 600px) {
      margin: 0 3px 0 0;
      position: relative;
      top: 3px;
    }

    &:last-of-type {
      margin-left: 5px;
      margin-top: 1px;
    }
  }
`;

export const AuthorTitle = styled.div`
  display: grid;
  grid-template-columns: .2fr 1fr;
  align-items: center;
  grid-gap: 20px;
  margin-bottom: 25px;

  h1 {
    font-size: 32px;
    margin-bottom: 15px;
  }
`;
