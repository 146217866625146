import React, { useState } from "react"
import { graphql } from "gatsby"
import parse from "html-react-parser"
import styled from "styled-components"
import BreadCrumbs from "../components/breadcrumbs"
import { Button } from "../components/styles/Buttons"
import "../css/style.scss"
import Footer from "../components/footer";
import SliderComponent from "../components/SliderComponent"
import { Subheading } from "../components/styles/Headings"
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Flex } from "../components/styles/Sections"
import ShareButtons from "../components/ShareButtons"

import "../css/@wordpress/block-library/build-style/style.css"
import "../css/@wordpress/block-library/build-style/theme.css"

import Bio from "../components/bio"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { GatsbyImage } from "gatsby-plugin-image"
// import ToC from "../components/TableOfContents"
import { SectionWrapper, SectionWrapperSmall, BottomDivider, SectionContainerGridTwoCols, SectionBlue } from "../components/styles/Sections"

const SlotTemplate = ({ data: { slot, relatedPosts }, location }) => {

  const featuredImage = {
    data: slot.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
    alt: slot.featuredImage?.node?.altText || ``,
  }

  const crumbs = [ 
    {
      "text": "Hem",
      "url": "https://svenskaonlinecasinon.se"
    },
    {
      "text": "Slots",
      "url": "https://svenskaonlinecasinon.se/slots"
    },
    {
      "text": slot.title,
    }
  ]

  // SEO VARIABLES 
  const schema = slot.seo.schema.raw;
  const canonicalUrl = slot.seo ? "https://svenskaonlinecasinon.se" + slot.seo.canonical : undefined;
  const ogImage = slot.featuredImage.node.sourceUrl;

  // END SEO VARIABLES

  const [showIframe, setIframe] = useState(false)
  const casinolist = slot && slot.slot ? slot.slot.casinolista : null;

  const PlaySlot = () => {
    setIframe(true)
  }

  const rtp = slot.slot && slot.slot.rtp;
  const volatility = slot.slot.volalitet && slot.slot.volalitet.toLowerCase();

  const volatilityPercentage = () => {
    switch(volatility) {
      case "låg": return 10;
      case "medelhög": return 50;
      case "hög": return 100;

      default: return volatility;
    }
  }

  return (
    <Layout>
    <Seo title={slot.seo.title} description={slot.seo.metaDesc} ogUrl={"https://svenskaonlinecasinon.se/" + slot.slug} ogType="article" canonical={canonicalUrl} ogImage={ogImage} schema={schema} />
    <BreadCrumbs items={crumbs} modified={slot.modified} />
    <SlotContent>
      <SectionWrapper>          
        <SectionContainerGridTwoCols className="slot">
          {featuredImage?.data && (
            <Image className="hide-xs">
              <GatsbyImage
                image={featuredImage.data}
                alt={featuredImage.alt}
                style={{borderRadius: 4}}
              />
            </Image>
          )}
          <div className="introduction">
            <header>
              <Flex className="responsive content">
                {featuredImage?.data && (
                  <Image className="hide-lg hide-sm mobile">
                    <GatsbyImage
                      image={featuredImage.data}
                      alt={featuredImage.alt}
                      style={{ 
                        borderRadius: 4
                      }}
                    />
                  </Image>
                )}
                <div>
                  <h1 itemProp="headline">{slot.title}</h1>
                  <span>{slot.tags.nodes[0].name}</span>
                </div>
              </Flex>
              <Flex className="gap-small responsive">
                {rtp && 
                  <column>
                    <CircularProgressbar
                      value={rtp}
                      text={`${rtp}%`}
                      styles={{
                        root: {},
                        path: {
                          stroke: `rgba(87, 164, 96, ${rtp / 100})`,
                          strokeLinecap: 'butt',
                          transition: 'stroke-dashoffset 0.5s ease 0s',
                          transform: 'rotate(0.25turn)',
                          transformOrigin: 'center center',
                        },
                        trail: {
                          stroke: '#d6d6d6',
                          strokeLinecap: 'butt',
                          transform: 'rotate(0.25turn)',
                          transformOrigin: 'center center',
                        },
                        text: {
                          fontWeight: "700",
                          fill: 'rgb(30 90 36)',
                          fontSize: '16px',
                        },
                        background: {
                          fill: '#e2fbd7',
                        },
                      }}
                    />
                    <span>RTP</span>
                  </column>
                }
                {volatility && 
                  <column>
                    <CircularProgressbar 
                      value={volatilityPercentage()}
                      text={`${volatility}`}
                      styles={{
                        root: {},
                        path: {
                          stroke: `rgba(12, 40, 178, ${volatilityPercentage / 100})`,
                          strokeLinecap: 'butt',
                          transition: 'stroke-dashoffset 0.5s ease 0s',
                          transform: 'rotate(0.25turn)',
                          transformOrigin: 'center center',
                        },
                        trail: {
                          stroke: '#d6d6d6',
                          strokeLinecap: 'butt',
                          transform: 'rotate(0.25turn)',
                          transformOrigin: 'center center',
                        },
                        text: {
                          fontWeight: "700",
                          fill: 'rgb(30 90 36)',
                          fontSize: '16px',
                        },
                        background: {
                          fill: '#e2fbd7',
                        },
                      }}
                    />
                    <span>Volalitet</span>
                  </column>
                }
              </Flex>
              {slot.slot.introduktion ? <p>{parse(slot.slot.introduktion)}</p> : undefined}
            </header>
            <table>
              <tbody>
                <tr>
                  <th>Lanserades: {slot.slot.lanserades}</th>
                  <th>RTP: {slot.slot.rtp}%</th>
                </tr>
                <tr>
                  <th>Volalitet: {slot.slot.volalitet}</th>
                  {slot.slot.maxvinst ? <th>Maxvinst: {slot.slot.maxvinst}</th> : null}
                </tr>
              </tbody>
            </table>
          </div>
        </SectionContainerGridTwoCols>
        {slot.slot.iframe &&
          <GameWrapper>
            {!showIframe ?
              <div className="overlay">
                <SectionContainerGridTwoCols className="button-wrapper">
                  <Button className="lightBlue medium" onClick={PlaySlot}>Spela gratis</Button>
                  <Button className="green medium" id="spela"><a href="#spela">Spela om pengar</a></Button>
                </SectionContainerGridTwoCols>
                <BottomDivider />

              </div>
            : 
              <div className="iframe-wrapper">
                {parse(slot.slot.iframe)}
                <Button className="green right large"><a href="#spela">Spela om pengar</a></Button>
              </div>
            }
          </GameWrapper>
        }
        </SectionWrapper>
        {casinolist &&
          <SectionBlue>
            <SectionWrapper>
              <Subheading id="spela" className="marginBottom">Spela {slot.title} här</Subheading>
              <SliderComponent items={casinolist} />
              <BottomDivider />
            </SectionWrapper>
          </SectionBlue>
        }
        <SectionWrapperSmall>
          {!!slot.content && (
            <article className="containerSmall">  
              <section className="padding-medium">
                {parse(slot.content)}
                <ShareButtons url={canonicalUrl} />
                <Bio author={slot.author.node} />
              </section>
            </article>
          )}
        </SectionWrapperSmall>
    </SlotContent>
    <Footer location={location} />
  </Layout>
  )
}

export default SlotTemplate

const SlotContent = styled.div`

  header {

    @media (max-width: 600px) {
      .content {
        background: #222;
        border-radius: 8px;
        padding: 15px;
        margin-bottom: 10px;
      }
    }

    h1 {
      margin-top: 0;
      margin-bottom: 5px;
      font-size: var(--fontSize-5);
      font-weight: var(--fontWeight-bold);

      @media (max-width: 600px) {
        font-size: 28px;
        color: #fff;
        margin-bottom: 0;
      }
    }

    span {
      font-weight: 600;
      display: block;
      color: var(--color-blue);

      @media (max-width: 600px) {
        font-size: 16px;
        color: var(--color-yellow);
      }
    }

    p {
      font-size: 18px;
      margin-top: 25px;
      margin-bottom: 20px;
    }

    .CircularProgressbar {
      width: 80px;
    }
  }

  .introduction {
    table {
      border-collapse: separate;

      tr {
        th {
          text-align: left;
          background: #f8f9fa;
          padding: 12px 15px;
          font-size: 16px;
        }
      }
    }
  }

  #article-content {
    padding: 25px 0 50px;
  }
`;

const GameWrapper = styled.div`
  position: relative;
  border-radius: 4px;
  overflow: hidden;
  margin: 50px 0 0;
  min-height: 550px;

  .overlay {
    position: absolute;
    display: flex;
    height: 550px;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: #000;
    z-index: 1;

    .button-wrapper {
      width: 65%;
    }
  }

  .iframe-wrapper {
    background: #000;
    padding: 20px;
    display: flex;
    flex-direction: column;

    iframe {
      border: 0;
      width: 100%;
      height: 550px;
    }

    .green {
      margin-top: 15px;
    }
  }
`;

const Image = styled.div`
  position: relative;

  .rtp {
    position: absolute;
    z-index: 1;
    width: 80px;
    top: -20px;
    right: -30px;
    background: #e2fbd7;
    border-radius: 50px;
  }

  &.mobile {
    max-width: 175px;
  }
`;

export const brandQuery = graphql`
  query SpelautomatById(
    $id: String!
  ) {
    slot: wpSlot(id: { eq: $id }) {
      id
      content
      title
      slug
      modified(locale: "sv-SE", formatString: "MMMM DD, YYYY")
      date(locale: "sv-SE", formatString: "MMMM DD, YYYY")
      seo {
        title
        metaDesc
        canonical
        schema {
          raw
        }
      }
      author {
        node {
          name
          description
          avatar {
            url
          }
          authorSocials {
            linkedin
          }
          uri
        }
      }
      slot {
        lanserades
        rtp
        maxvinst
        volalitet
        introduktion
        iframe
        casinolista {
          casino {
            ... on WpPost {
              title
              id
              uri
              slug
              affiliateLink {
                affiliateLink {
                  ... on WpAffiliateTracker {
                    id
                    uri
                  }
                }
              }
              casinoInformation {
                rating
              }
              featuredImage {
                node {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        placeholder: BLURRED
                        quality: 100
                      )
                    }
                  }
                }
              }
            }
          }
        }
      }
      tags {
        nodes {
          name
        }
      }
      modified(locale: "sv-SE", formatString: "MMMM DD, YYYY")
      date(locale: "sv-SE", formatString: "MMMM DD, YYYY")
      featuredImage {
        node {
          altText
          sourceUrl
          localFile {
            childImageSharp {
              gatsbyImageData(
                quality: 100
              )
            }
          }
        }
      }
    }
    relatedPosts: allWpSlot(limit: 5) {
      nodes {
        title
        uri
        id
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  layout: FULL_WIDTH
                )
              }
            }
          }
        }
      }
    }
  }
`
